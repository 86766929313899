import { graphql } from "gatsby"
import React from "react"

import { Seo } from "../components"

import { Hero, Auszug, AuszugTwo, Teaser } from "../sections/landing"

const IndexPage = ({ data }) => {
  const {
    hero,
    bereich,
    bereich2,
    bereich3,
    bereichTeaser1,
    bereichTeaser2,
    // bereichTeaser3,
    hintergrundbildTeaser1,
    hintergrundbildTeaser2,
    // hintergrundbildTeaser3,
  } = data.frontpage.landingpage
  return (
    <>
      <Seo />
      <Hero data={hero} />
      <Auszug data={bereich} showKategorie={false} />
      <Teaser data={bereichTeaser1} hintergrundBild={hintergrundbildTeaser1} />
      <Auszug data={bereich2} showKategorie={false} />
      <Teaser data={bereichTeaser2} hintergrundBild={hintergrundbildTeaser2} />
      <AuszugTwo data={bereich3} />

      {/* <Teaser
        data={bereichTeaser3}
        hintergrundBild={hintergrundbildTeaser3}
        textBox={false}
      /> */}
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    frontpage: wpPage(isFrontPage: { eq: true }) {
      landingpage {
        hero {
          titel
          text
          bild {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  tracedSVGOptions: { color: "#01214A" }
                )
              }
            }
          }
        }
        bereich {
          ... on WpPage {
            title
            uri
            pagebuilder {
              fixeElementeTop {
                teaserText
                kategorie
              }
            }
          }
        }
        bereich2 {
          ... on WpPage {
            title
            uri
            pagebuilder {
              fixeElementeTop {
                teaserText
                kategorie
              }
            }
          }
        }
        bereich3 {
          ... on WpPage {
            title
            uri
            featuredImage {
              ...FeaturedImage
            }
            pagebuilder {
              fixeElementeTop {
                teaserText
                kategorie
              }
            }
          }
        }
        bereichTeaser1 {
          ... on WpPage {
            uri
            title
            pagebuilder {
              fixeElementeTop {
                teaserText
              }
            }
          }
        }
        hintergrundbildTeaser1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bereichTeaser2 {
          ... on WpPage {
            uri
            title
            pagebuilder {
              fixeElementeTop {
                teaserText
              }
            }
          }
        }
        hintergrundbildTeaser2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        bereichTeaser3 {
          ... on WpPage {
            uri
            title
            pagebuilder {
              fixeElementeTop {
                teaserText
              }
            }
          }
        }
        hintergrundbildTeaser3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  fragment FeaturedImage on WpNodeWithFeaturedImageToMediaItemConnectionEdgeType {
    node {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
